export default {
  primary: [
    {
      url: '/',
      name: 'Dashboard',
      slug: 'dashboard',
      icon: 'menuDashboard'
    },
    {
      url: '/schedule',
      name: 'Appointments',
      slug: 'schedule',
      icon: 'menuBookings',
      // badge: 4,
      match: '/schedule',
      grants: {
        reservation: ['view']
      }
    },
    {
      url: '/order',
      name: 'Sales',
      slug: 'sale',
      icon: 'menuSales',
      // badge: 12,
      grants: {
        order: ['view']
      }
    },
    {
      url: '/customer',
      name: 'Customers',
      slug: 'customer',
      icon: 'menuCustomers',
      match: '/customer',
      grants: {
        customer: ['profile']
      }
    },
    {
      url: '/roster',
      name: 'Roster',
      slug: 'roster',
      icon: 'menuRoster',
      grants: {
        roster: ['view']
      }
    },
    {
      url: '/marketing',
      name: 'Marketing',
      slug: 'marketing',
      icon: 'menuMarketing',
      grants: {
        marketing: ['view']
      }
    },
    {
      url: '/message',
      name: 'Messages',
      slug: 'message',
      icon: 'menuMessage',
      grants: {
        marketing: ['view']
      },
      gates: [
        'messaging'
      ]
    },
    {
      url: '/report',
      name: 'Reports',
      icon: 'menuReports',
      match: '/report',
      grants: {
        report: ['view']
      }
    },
    {
      url: '/manage',
      name: 'Manage',
      icon: 'menuManage',
      match: '/manage'
    },
    {
      url: '/minisite',
      name: 'Mini-site',
      icon: 'menuOnlineBooking',
      match: '/minisite',
      grants: {
        account: ['onlineBooking']
      }
    }
  ],
  secondary: [
    {
      url: '/account',
      name: 'Account',
      icon: 'menuAccount',
      match: '/account',
      grants: {
        account: ['view']
      }
    },
    {
      url: 'https://ovatu.com/help/next/guides',
      name: 'Help',
      icon: 'menuHelp',
      external: true
    }
  ]
}
